// This file will contain all the column sort functions that will be used in the PrimeReact's DataTable component.
// TODO: Move all custom sort functions here. Make some of them reusable/generic if possible.

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { ColumnSortEvent } from 'primereact/column';

// Generic sort function to sort different types of values.
// It can sort by nested fields too. For that, you need to provide a proper sortField.
//    e.g. sortField = 'createdBy.firstName'
//
// If there are multiple fields to be considered together when sorting, you can provide them as a comma-separated string.
//    e.g. sortfield = 'createdBy.firstName,createdBy.lastName'
//    This will concatenate the values of createdBy.firstName and createdBy.lastName and then sort them.
export const genericSortFunction = (e: ColumnSortEvent) => {
  return e.data.sort((data1: any, data2: any) => {
    const val1 = concatenateFields(data1, e.field);
    const val2 = concatenateFields(data2, e.field);

    // Check if val1 and val2 are dates
    if (isDate(val1) && isDate(val2)) {
      return (e.order || 1) * (Date.parse(val1) - Date.parse(val2));
    }

    // Check if val1 and val2 are numbers
    if (isNumber(val1) && isNumber(val2)) {
      return (e.order || 1) * (parseFloat(val1) - parseFloat(val2));
    }

    // Default comparison for strings
    return (e.order || 1) * val1.localeCompare(val2);
  });
};

// Private helper function
// This will take a data object and a string of comma-separated fields, regardless if they are nested or not.
// It will concatenate the values of those fields and return the concatenated string.
// e.g.
//    data = { createdBy: { firstName: 'John', lastName: 'Doe' } }
//    fields = 'createdBy.firstName,createdBy.lastName'
//    => 'John Doe'
//
//    data = { dateCreated: '2021-01-01T00:00:00.000Z' }
//    fields = 'dateCreated'
//    => '2021-01-01T00:00:00.000Z'
//
//    data = { A: { B: "Hello" }, C: { D: "World" }, E: 999 }
//    fields = 'A.B,C.D,E'
//    => 'Hello World 999'
const concatenateFields = (data: any, fieldsCommaSeparated: string): string => {
  const concatenatedValues = fieldsCommaSeparated.split(',').reduce((acc, fieldName) => {
    const fields = fieldName.split('.');

    // Go to the deepest field
    const deepestValue = fields.reduce((acc, field) => {
      return acc[field];
    }, data);

    // Concatenate
    return acc + deepestValue.toString() + ' ';
  }, '');

  // Remove the last space and return
  return concatenatedValues.trim();
};

// Given a string, this function will return true if the string is a valid date string.
// e.g. a valid date string: '2021-01-01T00:00:00.000Z'
// Credits to https://stackoverflow.com/a/52869830/16078257
const isDate = (value: string): boolean => {
  const date = new Date(value);

  return !isNaN(date.getTime()) && date.toISOString() === value;
};

const isNumber = (value: string): boolean => {
  return !isNaN(parseFloat(value));
};
