import { useQueryClient } from '@tanstack/react-query';
import {
  confirmResetPassword as amplifyConfirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage, sessionStorage } from 'aws-amplify/utils';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CognitoErrorCode,
  CognitoErrorMessage,
  CognitoMfaErrorMessage,
  CognitoMfaErrorName,
} from '../../shared/enums/cognito-error-code';
import { LoginErrorCode } from '../../shared/enums/login-error-code';
import { User, UserRole } from '../../shared/types/User';
import { useGetUser } from '../api/users';

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User | null;
  signIn: (username: string, password: string, staySignedIn: boolean) => Promise<Result>;
  signOut: () => Promise<{ success: boolean; message: string }>;
  completeNewPassword: (
    username: string,
    oldPassowrd: string,
    newPassword: string,
  ) => Promise<Result>;
  sendVerificationCode: (username: string) => Promise<{ success: boolean; message: string }>;
  confirmResetPassword: (
    username: string,
    confirmCode: string,
    newPassword: string,
  ) => Promise<{ success: boolean; message: string }>;
  confirmMfaCode: (otpCode: string) => Promise<{ success: boolean; message: string }>;
}

interface Result {
  success: boolean;
  message: string;
  errorCode?: LoginErrorCode;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const auth = useAuthProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvider = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const { t } = useTranslation();

  const { refetch: fetchMyData, isStale } = useGetUser();

  const queryClient = useQueryClient();

  useEffect(() => {
    async function fetchUser() {
      try {
        // Get session
        const { idToken } = (await fetchAuthSession()).tokens ?? {};

        if (!idToken) {
          // If there is no session, then logout
          await amplifySignOut();
          setUser(null);
          setIsAuthenticated(false);
          setIsLoading(false);

          return;
        }

        const { username } = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();

        // If authentication check is sucessfull, then fetch user data from API
        const { data: userData } = await fetchMyData({ throwOnError: true });

        setUser({
          id: userData?.id,
          username: username,
          email: userData?.email ?? '',
          givenName: userData?.firstName ?? '',
          familyName: userData?.lastName ?? '',
          company: {
            id: userData?.company.id ?? 0,
            company_id: userData?.company.companyId ?? '', // eslint-disable-line camelcase
            company_name: userData?.company.companyName ?? '', // eslint-disable-line camelcase
            edcConfigurationStatus: userData?.company?.edcConfigurationStatus,
            license: userData?.company?.license || 'basic',
            mfa_enabled: userData?.company?.isMfaEnabled || false, // eslint-disable-line camelcase
          },
          role: userAttributes?.['custom:role'] as UserRole,
        });

        setIsAuthenticated(true);
        setIsLoading(false);
      } catch (error) {
        // If there is an error during the page refresh, then logout
        // Logout is needed, to clear the storages and reset the session
        await amplifySignOut();
        setUser(null);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    }

    fetchUser();
  }, [isStale]);

  const signIn = async (username: string, password: string, staySignedIn: boolean) => {
    try {
      // Configure storage for session
      if (staySignedIn) {
        // If staySignedIn is true, then use local storage, which is referred as default storage
        cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
      } else {
        // If staySignedIn is false, then use session storage
        cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
      }

      const result = await amplifySignIn({ username, password });

      if (result.isSignedIn) {
        // If login is successful, then go further and fetch user attributes/data
        const userAttributes = await fetchUserAttributes();
        const { idToken } = (await fetchAuthSession()).tokens ?? {};

        const userGroups = idToken?.payload['cognito:groups'] as string[];

        // If user is super-admin, then logout
        if (userGroups?.includes('super-admin')) {
          await amplifySignOut();

          return {
            success: false,
            message: '',
            errorCode: LoginErrorCode.InvalidRole,
          };
        }

        // If login is successful, then fetch user data from API
        const { data: userData } = await fetchMyData({ throwOnError: true });

        setUser({
          id: userData?.id,
          username: username,
          email: userData?.email ?? '',
          givenName: userData?.firstName ?? '',
          familyName: userData?.lastName ?? '',
          company: {
            id: userData?.company.id ?? 0,
            company_id: userData?.company.companyId ?? '', // eslint-disable-line camelcase
            company_name: userData?.company.companyName ?? '', // eslint-disable-line camelcase
            edcConfigurationStatus: userData?.company?.edcConfigurationStatus,
            license: userData?.company?.license || 'basic',
          },
          role: userAttributes?.['custom:role'] as UserRole,
        });

        setIsAuthenticated(true);

        return { success: true, message: result.nextStep.signInStep ?? '' };
      } else {
        return { success: false, message: result.nextStep.signInStep ?? '' };
      }
    } catch (error) {
      await amplifySignOut();
      setUser(null);
      setIsAuthenticated(false);
      setIsLoading(false);

      // Default error message
      let message = t('authPage.loginForm.errorMessage');
      let errorCode = LoginErrorCode.InvalidCredentials;

      // Check error cause, if user is disabled, then show user disabled message
      if ((error as Error).message === CognitoErrorMessage.UserDisabledException) {
        message = t('authPage.loginForm.userDisabledMessage');
        errorCode = LoginErrorCode.UserDisabled;
      }

      return {
        success: false,
        message: message,
        errorCode: errorCode,
      };
    }
  };

  const signOut = async () => {
    try {
      await amplifySignOut();

      setUser(null);
      setIsAuthenticated(false);
      queryClient.invalidateQueries(['users', 'me']);

      // Clear React Query cache
      queryClient.clear();

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: t('authPage.loginForm.logoutFail'),
      };
    }
  };

  const completeNewPassword = async (
    username: string,
    oldPassowrd: string,
    newPassword: string,
  ) => {
    try {
      const loginResult = await amplifySignIn({ username, password: oldPassowrd });

      if (loginResult.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        await confirmSignIn({
          challengeResponse: newPassword,
        });

        const userAttributes = await fetchUserAttributes();

        // If activation is successful, then fetch user data from API
        const { data: userData } = await fetchMyData({ throwOnError: true });

        setUser({
          id: userData?.id,
          username: username,
          email: userData?.email ?? '',
          givenName: userData?.firstName ?? '',
          familyName: userData?.lastName ?? '',
          role: userAttributes?.['custom:role'] as UserRole,
          company: {
            id: userData?.company.id ?? 0,
            company_id: userData?.company.companyId ?? '', // eslint-disable-line camelcase
            company_name: userData?.company.companyName ?? '', // eslint-disable-line camelcase
            edcConfigurationStatus: userData?.company?.edcConfigurationStatus,
            license: userData?.company?.license || 'basic',
          },
        });

        setIsAuthenticated(true);
      }

      return { success: true, message: '' };
    } catch (error) {
      const mappedMessage =
        CognitoErrorCode[(error as Error).name as keyof typeof CognitoErrorCode];

      const errorMessage = (error as Error).message;

      return {
        success: false,
        message: mappedMessage ?? errorMessage,
      };
    }
  };

  const sendVerificationCode = async (username: string) => {
    try {
      await resetPassword({ username });

      return {
        success: true,
        message: t('authPage.loginForm.SendCodeSuccessfully'),
      };
    } catch (error) {
      // Default error message
      let message = t('authPage.forgotPassword.errorMessage');

      if ((error as Error).message === CognitoErrorMessage.UserDisabledException) {
        message = t('authPage.forgotPassword.userDisabledMessage');
      } else if ((error as Error).message === CognitoErrorMessage.UserNotFoundException) {
        message = t('authPage.forgotPassword.userNotFound');
      }

      return {
        success: false,
        message: message,
      };
    }
  };

  const confirmResetPassword = async (
    username: string,
    confirmationCode: string,
    newPassword: string,
  ) => {
    try {
      await amplifyConfirmResetPassword({ username, confirmationCode, newPassword });

      return {
        success: true,
        message: t('authPage.loginForm.passwordResetSuccessfully'),
      };
    } catch (error) {
      const mappedMessage =
        CognitoErrorCode[(error as Error).name as keyof typeof CognitoErrorCode];
      const errorMessage = (error as Error).message;

      return {
        success: false,
        message: mappedMessage || errorMessage,
      };
    }
  };

  // Sign in using two factor authentication code
  const confirmMfaCode = async (otpCode: string) => {
    try {
      // Send the OTP code to AWS Cognito
      const mfaSignInResult = await confirmSignIn({
        challengeResponse: otpCode,
      });

      if (mfaSignInResult.isSignedIn) {
        const { username } = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();

        // Fetch user data from API
        const { data: userData } = await fetchMyData({ throwOnError: true });

        setUser({
          id: userData?.id,
          username: username,
          email: userData?.email ?? '',
          givenName: userData?.firstName ?? '',
          familyName: userData?.lastName ?? '',
          role: userAttributes?.['custom:role'] as UserRole,
          company: {
            id: userData?.company.id ?? 0,
            company_id: userData?.company.companyId ?? '', // eslint-disable-line camelcase
            company_name: userData?.company.companyName ?? '', // eslint-disable-line camelcase
            edcConfigurationStatus: userData?.company?.edcConfigurationStatus,
            license: userData?.company?.license || 'basic',
          },
        });

        setIsAuthenticated(true);

        return { success: true, message: '' };
      }

      return { success: false, message: '' };
    } catch (error) {
      await amplifySignOut();
      setUser(null);
      setIsAuthenticated(false);
      setIsLoading(false);

      const errorObject = error as Error;

      let message = '';

      if (errorObject.message === CognitoMfaErrorMessage.SessionExpired) {
        message = t('authPage.mfaForm.sessionExpiredErrorMessage');
      } else if (errorObject.name === CognitoMfaErrorName.CodeMismatchException) {
        message = t('authPage.mfaForm.invalidCodeErrorMessage');
      } else if (errorObject.message === CognitoMfaErrorMessage.TooManyInvalidAttempts) {
        message = t('authPage.mfaForm.tooManyInvalidAttemptsErrorMessage');
      } else if (
        errorObject.message.replace(/\t|\n/g, '') ===
        CognitoMfaErrorMessage.AttemptedWithoutSigningIn
      ) {
        message = t('authPage.mfaForm.attemptWithoutSigningInErrorMessage');
      }

      return { success: false, message: message || (error as Error).message };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    user,
    signIn,
    signOut,
    completeNewPassword,
    sendVerificationCode,
    confirmResetPassword,
    confirmMfaCode,
  };
};
