/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Skeleton } from 'primereact/skeleton';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useInplaceEdit } from '../../contexts/product-details-page-inplace-edit/ProductDetailsPageInplaceEditContext';
import { useGetProductById } from '../../hooks/api/products';
import ProductBreadCrumb from './components/product-breadcrumb/ProductBreadCrumb';
import ProductNameSection from './components/product-name-section/ProductNameSection';
import ProductTabView from './components/product-tab-view/ProductTabView';

const ProductDetailsPage = (): JSX.Element => {
  const { productId } = useParams() as { productId: string };

  // Destructure the inplace edit context
  const { setProductId: setContextProductId } = useInplaceEdit();

  // Set the productId in the inplace edit context
  useEffect(() => {
    setContextProductId(productId);
  }, [productId, setContextProductId]);

  const {
    data: product,
    isLoading,
    isError,
  } = useGetProductById(productId, {
    enabled: !!productId,
    retry: 0,
  });

  if (isError) {
    return <Navigate to='/404' />;
  }

  return (
    <div className='px-6 py-5 flex flex-column gap-2'>
      {/* Breadcrumb */}
      <ProductBreadCrumb isLoading={isLoading} productName={product?.productName} />

      {/* Product Name */}
      <ProductNameSection product={product!} isLoading={isLoading} />

      {/* Tabs */}
      {isLoading ? <Skeleton height='45px' width='30%' className='mt-4' /> : <ProductTabView />}
    </div>
  );
};

export default ProductDetailsPage;
