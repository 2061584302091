import { ColumnSortEvent } from 'primereact/column';

import {
  CountryCodeToLabelMap,
  RegionCodeToLabelMap,
} from '../../../shared/helpers/country-region-helpers';
import { IProduct } from '../../../shared/interfaces/IProduct';

export const productsTableLocationColumnSortFunction = (event: ColumnSortEvent): IProduct[] => {
  const data = [...event.data] as IProduct[];
  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    let location1 = '';
    let location2 = '';

    // Determine location1, either region or country
    if (data1.country) {
      location1 = CountryCodeToLabelMap[data1.country] || data1.country;
    }

    if (!data1.country && data1.region) {
      location1 = RegionCodeToLabelMap[data1.region] || data1.region;
    }

    // Determine location2, either region or country
    if (data2.country) {
      location2 = CountryCodeToLabelMap[data2.country] || data2.country;
    }

    if (!data2.country && data2.region) {
      location2 = RegionCodeToLabelMap[data2.region] || data2.region;
    }

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (location1 == null && location2 == null) result = 0;
    else if (location1 == null && location2 != null) result = -1;
    else if (typeof location1 === 'string' && typeof location2 === 'string')
      return location1.localeCompare(location2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};

export const productsTablePcfColumnSortFunction = (event: ColumnSortEvent): IProduct[] => {
  const data = [...event.data] as IProduct[];
  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const pcf1 = data1?.pcf?.pcfExcludingBiogenic || 0;
    const pcf2 = data2?.pcf?.pcfExcludingBiogenic || 0;

    let result = 1;

    if (pcf1 < pcf2) {
      result = -1;
    } else if (pcf1 > pcf2) {
      result = 1;
    } else {
      result = 0;
    }

    return eventOrder * result;
  });

  return data;
};

export const productsTableProductIdColumnSortFunction = (event: ColumnSortEvent): IProduct[] => {
  const data = [...event.data] as IProduct[];
  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const productId1 = data1.productId.toString();
    const productId2 = data2.productId.toString();

    let result = 1;

    // Using == instead of === to check for null or undefined
    if (productId1 == null && productId2 == null) result = 0;
    else if (productId1 == null && productId2 != null) result = -1;
    else if (typeof productId1 === 'string' && typeof productId2 === 'string')
      return productId1.localeCompare(productId2) * eventOrder;

    return eventOrder * result;
  });

  return data;
};
