import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PenFilledIcon from '../../../../assets/icons/PenFilled';
import PenOutlineIcon from '../../../../assets/icons/PenOutline';
import { useGetFootprintById } from '../../../../hooks/api/footprints';
import useSidebarContext from '../../../../hooks/sidebar/useSidebarContext';
import { Locale } from '../../../../i18n';
import CrossSectoralStandards from '../../../../shared/components/cross-sectoral-standards/CrossSectoralStandards';
import CustomIcon from '../../../../shared/components/custom-icon/CustomIcon';
import ProductOrSectorSpecificRules from '../../../../shared/components/product-or-sector-specific-rules/ProductOrSectorSpecificRules';
import SecondaryEmissionsFactorSources from '../../../../shared/components/secondary-emissions-factor-sources/SecondaryEmissionsFactorSources';
import { parseDateWithoutTime } from '../../../../shared/helpers/parse-date';
import {
  AllocationWasteIncinerationCharacteristicToLabelMap,
  FootPrintStatus,
  packagingEmissionValueToLabelKey,
} from '../../../../shared/interfaces/IFootprint';

const AdditionalDetailsSection = (): JSX.Element => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { openSidebar } = useSidebarContext();

  // Get footprintId from URL
  const { footprintId } = useParams() as { footprintId: string };

  // Getting the footprint details
  const { data: footprintDetails, isLoading } = useGetFootprintById(footprintId, {
    enabled: !!footprintId,
    retry: 0,
  });

  return (
    <div className='flex flex-column gap-2 w-full'>
      {/* Additional Details */}
      <div className='flex flex-column gap-3 pb-3'>
        <div className='flex flex-row gap-3 align-items-center pb-3'>
          <h4 className='m-0 p-0 font-semibold'>
            {t('footprintDetailsPage.sections.additionalDetailsSection.title')}
          </h4>
          {footprintDetails && footprintDetails.status === FootPrintStatus.DRAFT && (
            <CustomIcon
              icon={PenOutlineIcon}
              hoverIcon={PenFilledIcon}
              showTextOnHover
              hoverText={t('global.edit')}
              hoverTextColor='primary'
              className='cursor-pointer flex justify-content-center align-items-center'
              onClick={() => {
                openSidebar('edit-additional-details-sidebar', {
                  position: 'right',
                  footprintDetails,
                });
              }}
            />
          )}
        </div>

        {/* Cross Sectoral Standard */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.additionalDetailsSection.crossSectoralStandard')}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <CrossSectoralStandards
              standards={footprintDetails?.pcf?.cross_sectoral_standards_used ?? []}
            />
          )}
        </div>

        {/* Product- or Sector-specific Rules */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t(
              'footprintDetailsPage.sections.additionalDetailsSection.productOrSectorSpecificRules',
            )}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <ProductOrSectorSpecificRules
              rules={footprintDetails?.pcf?.product_or_sector_specific_rules ?? []}
            />
          )}
        </div>

        {/* Secondary Emissions Factor Sources*/}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t(
              'footprintDetailsPage.sections.additionalDetailsSection.secondaryEmissionsFactorSources',
            )}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <SecondaryEmissionsFactorSources
              sources={footprintDetails?.pcf?.secondary_emission_factor_sources ?? []}
            />
          )}
        </div>

        {/* Characterization Factors */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.additionalDetailsSection.characterizationFactors')}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className={'font-light text-black-alpha-60'}>
              {footprintDetails?.pcf?.characterization_factors ?? t('global.notSpecified')}
            </span>
          )}
        </div>

        {/* Allocation Waste Incineration */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t(
              'footprintDetailsPage.sections.additionalDetailsSection.allocationWasteIncineration',
            )}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className={'font-light text-black-alpha-60'}>
              {footprintDetails?.pcf?.allocation_waste_incineration
                ? AllocationWasteIncinerationCharacteristicToLabelMap[
                    footprintDetails.pcf.allocation_waste_incineration
                  ]
                : t('global.notSpecified')}
            </span>
          )}
        </div>

        {/* Exempted Emisions */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.additionalDetailsSection.exemptedEmissions')}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className={'font-light text-black-alpha-60'}>
              {footprintDetails?.pcf?.exempted_emissions_percent
                ? `${footprintDetails.pcf.exempted_emissions_percent}%`
                : t('global.notSpecified')}
            </span>
          )}
        </div>

        {/* Packaging Emissions */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.additionalDetailsSection.packagingEmissions')}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className={'font-light text-black-alpha-60'}>
              {t(
                packagingEmissionValueToLabelKey(
                  String(footprintDetails?.pcf?.packaging_emissions_included),
                ),
              )}
            </span>
          )}
        </div>

        {/* Partial or Full Pcf */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.additionalDetailsSection.partialOrFullPcf')}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className={'font-light text-black-alpha-60'}>
              {footprintDetails?.partial_full_pcf ?? t('global.notSpecified')}
            </span>
          )}
        </div>

        {/* Reference Period */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.additionalDetailsSection.referencePeriod')}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className='font-light text-black-alpha-60'>
              {footprintDetails?.pcf?.reference_period_start &&
              footprintDetails?.pcf?.reference_period_end ? (
                <div className='flex flex-row gap-1'>
                  <i className='pi pi-calendar' />
                  {`${parseDateWithoutTime(
                    footprintDetails.pcf.reference_period_start,
                    language as Locale,
                  )} - ${parseDateWithoutTime(
                    footprintDetails.pcf.reference_period_end,
                    language as Locale,
                  )}`}
                </div>
              ) : (
                t('global.notSpecified')
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetailsSection;
