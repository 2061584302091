/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { ICompany } from '../../shared/interfaces/ICompany';
import { IUser, UserStatus } from '../../shared/interfaces/IUser';
import { UserRole } from '../../shared/types/User';

type IUserDetails = Pick<IUser, 'id' | 'firstName' | 'lastName' | 'email'> & {
  company: Pick<
    ICompany,
    'id' | 'companyId' | 'companyName' | 'edcConfigurationStatus' | 'license' | 'isMfaEnabled'
  >;
  language: string;
};

type AddUserMutationProps = {
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  language: string;
  role: UserRole;
};
type AddUserResponse = { id: number };

type UpdateUserNameMutationProps = {
  userId: number;
  firstName: string;
  lastName: string;
};

type UpdateUserNameResponse = { id: number };

type GetInvitationLinkMutationProps = {
  userId: number;
};
export type GetInvitationLinkResponse = {
  inviteLink: string;
};

type AssignAdminRoleMutationProps = {
  userId: number;
};

type UpdateUserStatusMutationProps = {
  userId: number;
  status: UserStatus;
};

type CancelInvitationMutationProps = {
  userId: number;
};

const amplifyApiService = new AmplifyApiService();

const BASE_PATH = 'users';

export const useGetUser = (
  options: UseQueryOptions<IUserDetails, Error> = {},
): UseQueryResult<IUserDetails> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `${BASE_PATH}/me`,
    options: {},
  };

  const query = useQuery<IUserDetails, Error>({
    queryKey: [BASE_PATH, 'me'],
    queryFn: async (): Promise<IUserDetails> => {
      const response: IUserDetails = await amplifyApiService.get<IUserDetails>(amplifyApiConfig);

      return response;
    },
    enabled: false,
    ...options,
  });

  return query;
};

export const useAddUser = (
  options: UseMutationOptions<AddUserResponse, Error, AddUserMutationProps> = {},
): UseMutationResult<AddUserResponse, Error, AddUserMutationProps> => {
  const mutation = useMutation<AddUserResponse, Error, AddUserMutationProps>({
    mutationFn: async (body: AddUserMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: BASE_PATH,
        options: { body },
      };

      return await amplifyApiService.post<AddUserMutationProps, AddUserResponse>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useUpdateUserName = (
  options: UseMutationOptions<UpdateUserNameResponse, Error, UpdateUserNameMutationProps> = {},
): UseMutationResult<UpdateUserNameResponse, Error, UpdateUserNameMutationProps> => {
  const mutation = useMutation<UpdateUserNameResponse, Error, UpdateUserNameMutationProps>({
    mutationFn: async ({ userId, firstName, lastName }: UpdateUserNameMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH}/${userId}`,
        options: {
          body: {
            firstName,
            lastName,
          },
        },
      };

      return await amplifyApiService.put<
        { first_name: string; last_name: string },
        UpdateUserNameResponse
      >(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useListUsers = (
  options: UseQueryOptions<IUser[], Error> = {},
): UseQueryResult<IUser[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: BASE_PATH,
    options: {},
  };

  const query = useQuery<IUser[], Error>({
    queryKey: [BASE_PATH],
    queryFn: async (): Promise<IUser[]> => {
      const response = await amplifyApiService.get<IUser[]>({
        ...amplifyApiConfig,
      });

      return response;
    },
    ...options,
  });

  return query;
};

export const useGetInvitationLink = (
  options: UseMutationOptions<
    GetInvitationLinkResponse,
    Error,
    GetInvitationLinkMutationProps
  > = {},
): UseMutationResult<GetInvitationLinkResponse, Error, GetInvitationLinkMutationProps> => {
  const mutation = useMutation<GetInvitationLinkResponse, Error, GetInvitationLinkMutationProps>({
    mutationFn: async ({ userId }: GetInvitationLinkMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH}/${userId}/get-invite-link`,
      };
      const response = await amplifyApiService.put<undefined, GetInvitationLinkResponse>(
        amplifyApiConfig,
      );

      return response;
    },
    ...options,
  });

  return mutation;
};

export const useAssignAdminRole = (
  options: UseMutationOptions<void, Error, AssignAdminRoleMutationProps> = {},
): UseMutationResult<void, Error, AssignAdminRoleMutationProps> => {
  const mutation = useMutation<void, Error, AssignAdminRoleMutationProps>({
    mutationFn: async ({ userId }: AssignAdminRoleMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH}/${userId}/update-role`,
        options: { body: { role: 'company-admin' } },
      };

      return await amplifyApiService.put<{ role: UserRole }, void>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useUpdateUserStatus = (
  options: UseMutationOptions<void, Error, UpdateUserStatusMutationProps> = {},
): UseMutationResult<void, Error, UpdateUserStatusMutationProps> => {
  const mutation = useMutation<void, Error, UpdateUserStatusMutationProps>({
    mutationFn: async ({ userId, status }: UpdateUserStatusMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH}/${userId}/update-status`,
        options: { body: { status } },
      };

      return await amplifyApiService.put<{ status: UserStatus }, void>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useCancelInvitation = (
  options: UseMutationOptions<void, Error, CancelInvitationMutationProps> = {},
): UseMutationResult<void, Error, CancelInvitationMutationProps> => {
  const mutation = useMutation<void, Error, CancelInvitationMutationProps>({
    mutationFn: async ({ userId }: CancelInvitationMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH}/${userId}`,
        options: {},
      };

      return await amplifyApiService.delete<undefined, void>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};
/* eslint-enable camelcase */
