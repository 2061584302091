/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IComponent } from '../../shared/interfaces/IComponent';
import { IComponentDetails } from '../../shared/interfaces/IProductDetails';

export type ComponentsParams = {
  status?: string;
  region?: string;
  od?: string;
  search?: string;
};

export type UpdateProductOwnDataMutationParams = {
  componentId: string;
  ownProductId?: string;
  ownProductName?: string;
};

export type UpdateProductOwnDataInput = {
  ownProductName?: string;
  ownProductId?: string;
};

type ExportPcfsProps = {
  componentIds: number[];
};

export type ExportPcfsResponse = {
  fileUrl: string;
  successCount: number;
  errorCount: number;
};

type RequestSustainableAlternativeProps = {
  componentId: number;
};

type BulkRequestPcfsProps = {
  componentIds: number[];
};

type BulkRequestPcfsResponse =
  | {
      successCount?: number;
      failureCount?: number;
    }
  | string;

const amplifyApiService = new AmplifyApiService();

const BASE_PATH_COMPONENTS = 'components';
const BASE_PATH_COMPANY_COMPONENTS = 'company-components';
const BASE_PATH_REQUEST = 'requests';

export const useListComponents = (
  params: ComponentsParams = {},
  options: UseQueryOptions<IComponent[], Error> = {},
): UseQueryResult<IComponent[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: BASE_PATH_COMPONENTS,
    options: {
      queryParams: params,
    },
  };

  const query = useQuery<IComponent[], Error>({
    queryKey: [BASE_PATH_COMPONENTS, params],
    queryFn: async (): Promise<IComponent[]> => {
      const response = await amplifyApiService.get<IComponent[]>(amplifyApiConfig);

      return response;
    },
    ...options,
  });

  return query;
};

export const useGetComponentDetails = (
  componentId: string,
  options: UseQueryOptions<IComponentDetails, Error> = {},
): UseQueryResult<IComponentDetails, Error> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `${BASE_PATH_COMPONENTS}/${componentId}`,
    options: {},
  };

  const query = useQuery<IComponentDetails, Error>({
    queryKey: [BASE_PATH_COMPONENTS, componentId],
    queryFn: async (): Promise<IComponentDetails> => {
      // First get JSON download link from PACIFIC API
      const response = await amplifyApiService.get<IComponentDetails>(amplifyApiConfig);

      return response;
    },
    ...options,
  });

  return query;
};

export const useUpdateComponentOwnData = (
  options: UseMutationOptions<void, Error, UpdateProductOwnDataMutationParams> = {},
): UseMutationResult<void, Error, UpdateProductOwnDataMutationParams> => {
  const mutation = useMutation<void, Error, UpdateProductOwnDataMutationParams>({
    mutationFn: async ({
      componentId,
      ownProductId,
      ownProductName,
    }: UpdateProductOwnDataMutationParams): Promise<void> => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH_COMPANY_COMPONENTS}/${componentId}`,
        options: {
          body: { ownProductId, ownProductName },
        },
      };

      await amplifyApiService.patch<UpdateProductOwnDataInput, void>(amplifyApiConfig);

      return;
    },

    ...options,
  });

  return mutation;
};

export const useExportPcfs = (
  options: UseMutationOptions<ExportPcfsResponse, Error, ExportPcfsProps> = {},
): UseMutationResult<ExportPcfsResponse, Error, ExportPcfsProps> => {
  const mutation = useMutation<ExportPcfsResponse, Error, ExportPcfsProps>({
    mutationFn: async (componentIds: ExportPcfsProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH_REQUEST}/download-csv`,
        options: {
          body: componentIds,
        },
      };

      return await amplifyApiService.post<ExportPcfsProps, ExportPcfsResponse>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useRequestSustainableAlternative = (
  options: UseMutationOptions<void, Error, RequestSustainableAlternativeProps> = {},
): UseMutationResult<void, Error, RequestSustainableAlternativeProps> => {
  const mutation = useMutation<void, Error, RequestSustainableAlternativeProps>({
    mutationFn: async ({ componentId }: RequestSustainableAlternativeProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH_REQUEST}/request-sustainable-alternative`,
        options: {
          body: { componentId },
        },
      };

      await amplifyApiService.post<RequestSustainableAlternativeProps, void>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useBulkRequestPcfs = (
  options: UseMutationOptions<BulkRequestPcfsResponse, Error, BulkRequestPcfsProps> = {},
): UseMutationResult<BulkRequestPcfsResponse, Error, BulkRequestPcfsProps> => {
  const mutation = useMutation<BulkRequestPcfsResponse, Error, BulkRequestPcfsProps>({
    mutationFn: async (componentIds: BulkRequestPcfsProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `${BASE_PATH_REQUEST}/request-bulk-pcf`,
        options: {
          body: componentIds,
        },
      };

      return await amplifyApiService.post<BulkRequestPcfsProps, BulkRequestPcfsResponse>(
        amplifyApiConfig,
      );
    },
    ...options,
  });

  return mutation;
};
