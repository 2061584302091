import { Button, ButtonProps } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import useDialogContext from '../../../../../../../../hooks/dialog/useDialogContext';

interface AddCompanyAccessButtonProps extends ButtonProps {
  productId: string;
}

const AddCompanyAccessButton = ({
  productId,
  ...restProps
}: AddCompanyAccessButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog } = useDialogContext();

  const onClick = () => {
    openDialog('add-company-access-dialog', { productId });
  };

  return (
    <Button
      onClick={onClick}
      label={t('productDetailsPage.tabs.permissionsTab.buttons.addCompanyAccess')}
      icon='pi pi-plus-circle'
      className='max-w-fit flex'
      {...restProps}
    />
  );
};

export default AddCompanyAccessButton;
