export enum EDCConfigurationStatus {
  PENDING = 'pending',
  FIRST_STEP_COMPLETED = 'first_step_completed',
  COMPLETED = 'completed',
}

export type License = 'basic' | 'pro' | 'automotive';

export interface ICompany {
  id: number;
  companyId: string;
  companyName: string;
  allowedJsonPcf: number;
  edcConfigurationStatus: EDCConfigurationStatus;
  license: License;
  isMfaEnabled: boolean;
}

export interface ICompanyBpn {
  bpn: string;
  name: string;
}
