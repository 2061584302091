import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';

import EmptyScreen from '../../../../../../../../components/empty-screen/EmptyScreen';
import { IPermission } from '../../../../../../../../hooks/api/permissions';
import useDialogContext from '../../../../../../../../hooks/dialog/useDialogContext';
import { Locale } from '../../../../../../../../i18n';
import { genericSortFunction } from '../../../../../../../../shared/helpers/column-sort-functions';
import { parseDateWithoutTime } from '../../../../../../../../shared/helpers/parse-date';
import AddCompanyAccessButton from '../add-company-access-button/AddCompanyAccessButton';
import styles from './PermissionsTable.module.css';

type PermissionsTableProps = {
  productId: string;
  permissions: IPermission[];
  isActivePcfExist: boolean;
};

const PermissionsTable = ({
  productId,
  permissions,
  isActivePcfExist,
}: PermissionsTableProps): JSX.Element => {
  const { openDialog } = useDialogContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const withdrawTemplate = (rowData: IPermission): JSX.Element => {
    return (
      <Button
        outlined
        label={t('productDetailsPage.tabs.permissionsTab.buttons.withdraw')}
        onClick={() => {
          return openDialog('withdraw-permission-dialog', {
            permission: rowData,
          });
        }}
      />
    );
  };

  const dateApprovedTemplate = (rowData: IPermission): JSX.Element => {
    return <span>{parseDateWithoutTime(rowData.dateCreated, language as Locale)}</span>;
  };

  const approvedByTemplate = (rowData: IPermission): JSX.Element => {
    return (
      <div className='flex gap-1'>
        <span>{rowData.createdBy.firstName}</span>
        <span>{rowData.createdBy.lastName}</span>
      </div>
    );
  };

  if (!permissions.length) {
    if (isActivePcfExist) {
      return (
        <EmptyScreen>
          <p className='flex text-center font-light'>
            {t('productDetailsPage.tabs.permissionsTab.emptyScreenTextWithActivePcf')}
          </p>
          <AddCompanyAccessButton productId={productId} />
        </EmptyScreen>
      );
    }

    return (
      <EmptyScreen>
        <p className='flex text-center font-light'>
          {t('productDetailsPage.tabs.permissionsTab.emptyScreenTextWithoutActivePcf')}
        </p>
      </EmptyScreen>
    );
  }

  return (
    <div className={styles['permissions-table']}>
      <DataTable
        value={permissions}
        pt={{
          wrapper: {
            className: styles['table-wrapper'],
          },
        }}
      >
        <Column
          field='companyName'
          sortable
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.companyName')}
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column
          field='bpn'
          sortable
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.bpn')}
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.dateApproved')}
          body={dateApprovedTemplate}
          sortable
          sortFunction={genericSortFunction}
          sortField='dateCreated'
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.approvedBy')}
          body={approvedByTemplate}
          sortable
          sortFunction={genericSortFunction}
          sortField='createdBy.firstName,createdBy.lastName'
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column body={withdrawTemplate} style={{ minWidth: '12rem', maxWidth: '16rem' }} />
      </DataTable>
    </div>
  );
};

export default PermissionsTable;
