/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';

const amplifyApiService = new AmplifyApiService();

const BASE_PATH = 'permissions';

export interface IPermission {
  id: number;
  bpn: string;
  companyName: string;
  dateCreated: string;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
  };
  productId: number;
}

export interface CreatePermissionRequest {
  bpn: string;
  productId: number;
}

export const useListPermissions = (
  productId: string,
  options: UseQueryOptions<IPermission[], Error> = {},
): UseQueryResult<IPermission[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: BASE_PATH,
    options: {
      queryParams: {
        productId,
      },
    },
  };

  const query = useQuery<IPermission[], Error>({
    queryKey: ['permissions', productId],
    queryFn: async (): Promise<IPermission[]> => {
      const response = await amplifyApiService.get<IPermission[]>(amplifyApiConfig);

      return response;
    },
    ...options,
  });

  return query;
};

export const useCreatePermission = (
  options: UseMutationOptions<IPermission, Error, CreatePermissionRequest> = {},
): UseMutationResult<IPermission, Error, CreatePermissionRequest> => {
  const mutation = useMutation<IPermission, Error, CreatePermissionRequest>({
    mutationFn: async (body: CreatePermissionRequest) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: BASE_PATH,
        options: { body },
      };

      return await amplifyApiService.post<CreatePermissionRequest, IPermission>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};
