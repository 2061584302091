import { lazy } from 'react';

export const DialogMapper = {
  'about-dialog': lazy(() => {
    return import('./dialogs/about-dialog/AboutDialog');
  }),
  'bulk-request-pcfs-dialog': lazy(() => {
    return import('./dialogs/bulk-request-pcfs-dialog/BulkRequestPcfsDialog');
  }),
  'check-for-sustainable-alternative-dialog': lazy(() => {
    return import(
      './dialogs/check-for-sustainable-alternative-dialog/CheckForSustainableAlternativeDialog'
    );
  }),
  'export-pcfs-dialog': lazy(() => {
    return import('./dialogs/export-pcfs-dialog/ExportPcfsDialog');
  }),
  'pcf-info-dialog': lazy(() => {
    return import('./dialogs/pcf-info-dialog/PCFInfoDialog');
  }),
  'request-additional-component-dialog': lazy(() => {
    return import('./dialogs/request-additional-component-dialog/RequestAdditionalComponentDialog');
  }),
  'request-pcf-dialog': lazy(() => {
    return import('./dialogs/request-pcf-dialog/RequestPcfDialog');
  }),
  'reactivate-user-dialog': lazy(() => {
    return import('./dialogs/reactivate-user-dialog/ReactivateUserDialog');
  }),
  'deactivate-user-dialog': lazy(() => {
    return import('./dialogs/deactivate-user-dialog/DeactivateUserDialog');
  }),
  'add-user-dialog': lazy(() => {
    return import('./dialogs/add-user-dialog/AddUserDialog');
  }),
  'assign-admin-role-dialog': lazy(() => {
    return import('./dialogs/assign-admin-role-dialog/AssignAdminRoleDialog');
  }),
  'cancel-invitation-dialog': lazy(() => {
    return import('./dialogs/cancel-invitation-dialog/CancelInvitationDialog');
  }),
  'add-product-dialog': lazy(() => {
    return import('./dialogs/add-product-dialog/AddProductDialog');
  }),
  'create-pcf-dialog': lazy(() => {
    return import('./dialogs/create-pcf-dialog/CreatePCFDialog');
  }),
  'share-pcf-dialog': lazy(() => {
    return import('./dialogs/share-pcf-dialog/SharePcfDialog');
  }),
  'add-company-access-dialog': lazy(() => {
    return import('./dialogs/add-company-access-dialog/AddCompanyAccessDialog');
  }),
  'withdraw-permission-dialog': lazy(() => {
    return import('./dialogs/withdraw-permission-dialog/WithdrawPermissionDialog');
  }),
  'publish-pcf-confirmation-dialog': lazy(() => {
    return import('./dialogs/publish-pcf-confirmation-dialog/PublishPCFConfirmationDialog');
  }),
  'enable-two-factor-authentication-diaog': lazy(() => {
    return import(
      './dialogs/enable-two-factor-authentication-dialog/EnableTwoFactorAuthenticationDialog'
    );
  }),
};

export type DialogType = keyof typeof DialogMapper;
