import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PenFilledIcon from '../../../../assets/icons/PenFilled';
import PenOutlineIcon from '../../../../assets/icons/PenOutline';
import { useGetFootprintById } from '../../../../hooks/api/footprints';
import useSidebarContext from '../../../../hooks/sidebar/useSidebarContext';
import CustomIcon from '../../../../shared/components/custom-icon/CustomIcon';
import EmissionValue from '../../../../shared/components/emission-value/EmissionValue';
import { FootPrintStatus } from '../../../../shared/interfaces/IFootprint';

const EmissionsSection = (): JSX.Element => {
  const { t } = useTranslation();
  const { openSidebar } = useSidebarContext();

  // Get footprintId from URL
  const { footprintId } = useParams() as { footprintId: string };

  // Getting the footprint details
  const { data: footprintDetails, isLoading } = useGetFootprintById(footprintId, {
    enabled: !!footprintId,
    retry: 0,
  });

  return (
    <div className='flex flex-column gap-2 w-full'>
      {/* Emissions */}
      <div className='flex flex-row gap-3 align-items-center pb-3'>
        <h4 className='m-0 p-0 font-semibold'>
          {t('footprintDetailsPage.sections.emissionsSection.title')}
        </h4>
        {footprintDetails && footprintDetails.status === FootPrintStatus.DRAFT && (
          <CustomIcon
            icon={PenOutlineIcon}
            hoverIcon={PenFilledIcon}
            showTextOnHover
            hoverText={t('global.edit')}
            hoverTextColor='primary'
            className='cursor-pointer flex justify-content-center align-items-center'
            onClick={() => {
              openSidebar('edit-emissions-details-sidebar', {
                position: 'right',
                footprintDetails: footprintDetails,
              });
            }}
          />
        )}
      </div>

      <div className='flex flex-column gap-4'>
        {/* Fossil Emissions */}
        <div className='flex flex-column gap-2 pb-3'>
          <h5 className='m-0 p-0 font-semibold'>
            {t('footprintDetailsPage.sections.emissionsSection.fossilEmissionsTitle')}
          </h5>
          {/* Fossil Ghg Emissions */}
          <div className='flex flex-column gap-2'>
            <span className='font-medium'>
              {t('footprintDetailsPage.sections.emissionsSection.fossilGhgEmissions')}
            </span>
            {isLoading ? (
              <Skeleton height='16px' />
            ) : (
              <EmissionValue
                amount={footprintDetails?.pcf?.fossil_ghg_emissions}
                declaredUnit={footprintDetails?.pcf?.declared_unit}
                suffixType='kgCO2e'
              />
            )}
          </div>
        </div>

        {/* Carbon Content */}
        <div className='flex flex-column gap-2 pb-3'>
          <h5 className='m-0 p-0 font-semibold'>
            {t('footprintDetailsPage.sections.emissionsSection.carbonContentTitle')}
          </h5>
          <div className='flex flex-column md:flex-row gap-3 md:gap-6 w-full'>
            {/* Fossil Carbon Content */}
            <div className='flex flex-column gap-2'>
              <span className='font-medium'>
                {t('footprintDetailsPage.sections.emissionsSection.fossilCarbonContent')}
              </span>
              {isLoading ? (
                <Skeleton height='16px' />
              ) : (
                <EmissionValue
                  amount={footprintDetails?.pcf?.fossil_carbon_content}
                  declaredUnit={footprintDetails?.pcf?.declared_unit}
                  suffixType='kgC'
                />
              )}
            </div>

            {/* Biogenic Carbon Content */}
            <div className='flex flex-column gap-2'>
              <span className='font-medium'>
                {t('footprintDetailsPage.sections.emissionsSection.biogenicCarbonContent')}
              </span>
              {isLoading ? (
                <Skeleton height='16px' />
              ) : (
                <EmissionValue
                  amount={footprintDetails?.pcf?.carbon_content_biogenic}
                  declaredUnit={footprintDetails?.pcf?.declared_unit}
                  suffixType='kgC'
                />
              )}
            </div>
          </div>
        </div>

        {/* Total */}
        <div className='flex flex-column gap-2'>
          <h5 className='m-0 p-0 font-semibold'>
            {t('footprintDetailsPage.sections.emissionsSection.totalTitle')}
          </h5>
          <div className='flex flex-column gap-3'>
            {/* Pcf excl */}
            <div className='flex flex-column gap-2'>
              <span className='font-medium text-primary'>
                {t(
                  'footprintDetailsPage.sections.emissionsSection.pcfExclBiogenicEmissionsAndRemovals',
                )}
              </span>
              {isLoading ? (
                <Skeleton height='16px' />
              ) : (
                <EmissionValue
                  amount={footprintDetails?.pcf?.pcf_excluding_biogenic}
                  declaredUnit={footprintDetails?.pcf?.declared_unit}
                  suffixType='kgCO2e'
                  amountTextClassname='text-primary font-bold'
                  unitTextClassname='text-primary font-light'
                />
              )}
            </div>
            {/* Pcf incl */}
            <div className='flex flex-column gap-2'>
              <span className='font-medium'>
                {t(
                  'footprintDetailsPage.sections.emissionsSection.pcfInclBiogenicEmissionsAndRemovals',
                )}
              </span>
              {isLoading ? (
                <Skeleton height='16px' />
              ) : (
                <EmissionValue
                  amount={footprintDetails?.pcf?.pcf_including_biogenic}
                  declaredUnit={footprintDetails?.pcf?.declared_unit}
                  amountTextClassname='text-black font-bold'
                  suffixType='kgCO2e'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmissionsSection;
