/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IRequest } from '../../shared/interfaces/IRequest';

interface IRequestPdfDownloadUrl {
  downloadUrl: string;
}

interface IRequestJsonDownloadUrl {
  data: unknown;
}

type RequestPcfProps = {
  componentId?: number;
  regionType?: string;
  requestType: string;
  requestDescription?: string;
};

export type PcfRequestsParams = {
  request_type?: string;
  search?: string;
};

const amplifyApiService = new AmplifyApiService();

const BASE_PATH = 'requests';

export const useGetPcfRequests = (
  filters: PcfRequestsParams = {},
  options: UseQueryOptions<IRequest[], Error> = {},
): UseQueryResult<IRequest[], Error> => {
  // Clear undefined values from filter

  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: BASE_PATH,
    options: {
      queryParams: filters,
    },
  };

  const query = useQuery<IRequest[], Error>({
    queryKey: [BASE_PATH, filters],
    queryFn: async (): Promise<IRequest[]> => {
      const response = await amplifyApiService.get<IRequest[]>(amplifyApiConfig);

      return response;
    },
    ...options,
  });

  return query;
};

export const useCreatePcfRequest = (
  options: UseMutationOptions<void, Error, RequestPcfProps> = {},
): UseMutationResult<void, Error, RequestPcfProps> => {
  const mutation = useMutation<void, Error, RequestPcfProps>({
    mutationFn: async (requestPcf: RequestPcfProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: BASE_PATH,
        options: {
          body: requestPcf,
        },
      };

      await amplifyApiService.post<RequestPcfProps, { id: number }>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useGetRequestPdfDownloadUrl = (
  id?: number,
): UseQueryResult<IRequestPdfDownloadUrl> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `${BASE_PATH}/${id}/get-download-pdf`,
    options: {},
  };

  const query = useQuery<IRequestPdfDownloadUrl, Error>({
    queryKey: [BASE_PATH, id, 'get-download-pdf'],
    queryFn: async (): Promise<IRequestPdfDownloadUrl> => {
      const response = await amplifyApiService.get<IRequestPdfDownloadUrl>(amplifyApiConfig);

      return response;
    },
    enabled: false,
  });

  return query;
};

export const useGetRequestJsonDownloadUrl = (
  id?: number,
): UseQueryResult<IRequestJsonDownloadUrl> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `${BASE_PATH}/${id}/get-download-json`,
    options: {},
  };

  const query = useQuery<IRequestJsonDownloadUrl, Error>({
    queryKey: [BASE_PATH, id, 'get-download-json'],
    queryFn: async (): Promise<IRequestJsonDownloadUrl> => {
      const response = await amplifyApiService.get<IRequestJsonDownloadUrl>(amplifyApiConfig);

      return response;
    },
    enabled: false,
  });

  return query;
};
