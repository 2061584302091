import {} from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomSpinner from '../../../../../../components/custom-spinner/CustomSpinner';
import { useGetFootprintsByProductId } from '../../../../../../hooks/api/footprints';
import { useListPermissions } from '../../../../../../hooks/api/permissions';
import { FootPrintStatus } from '../../../../../../shared/interfaces/IFootprint';
import AddCompanyAccessButton from './components/add-company-access-button/AddCompanyAccessButton';
import PermissionsTable from './components/permissions-table/PermissionsTable';

export type dummyDataType = {
  companyName: string;
  bpn: string;
  dateApproved: string;
  approvedBy: string;
};

type PermissionsTabProps = {
  productId: string;
};

const PermissionsTab = ({ productId }: PermissionsTabProps): JSX.Element => {
  const { t } = useTranslation();

  const [isActivePcfExist, setIsActivePcfExist] = useState(false);

  const { data: permissions = [], isLoading: isPermissionsLoading } = useListPermissions(productId);
  const { data: footprints = [], isLoading: isFootprintsLoading } =
    useGetFootprintsByProductId(productId);

  useEffect(() => {
    if (footprints.length > 0) {
      // Check if there is any active pcf
      const _isActivePcfExist = footprints.some((footprint) => {
        return footprint.status === FootPrintStatus.PUBLISHED;
      });

      setIsActivePcfExist(_isActivePcfExist);
    }
  }, [footprints]);

  if (isPermissionsLoading || isFootprintsLoading) {
    return (
      <div className='w-full flex justify-content-center'>
        <CustomSpinner strokeWidth={4} className='w-4rem mt-6' />
      </div>
    );
  }

  return (
    <div className='flex flex-column w-full'>
      <h1 className='text-xl md:text-2xl font-medium p-0 mt-3'>
        {t('productDetailsPage.tabs.permissionsTab.title')}
      </h1>

      <PermissionsTable
        productId={productId}
        permissions={permissions}
        isActivePcfExist={isActivePcfExist}
      />

      {permissions.length > 0 && (
        <div className='mt-3'>
          <AddCompanyAccessButton productId={productId} disabled={!isActivePcfExist} />
        </div>
      )}
    </div>
  );
};

export default PermissionsTab;
