import EmissionValue from '../../../shared/components/emission-value/EmissionValue';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { IComponent } from '../../../shared/interfaces/IComponent';
import { DeclaredUnit } from '../../../shared/interfaces/IProductDetails';

type ComponentPcfValueProps = {
  component: IComponent;
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
};

const ComponentPcfValue = ({
  component,
  biogenicEmissionsMode,
}: ComponentPcfValueProps): JSX.Element | null => {
  if (biogenicEmissionsMode === PCFBiogenicEmissionsMode.Include && component.pcfIncluding) {
    return (
      <EmissionValue
        amount={component.pcfIncluding}
        declaredUnit={DeclaredUnit.kilogram}
        suffixType='kgCO2e'
        abbreviateUnit={true}
        amountTextClassname='font-bold'
        unitTextClassname='font-light'
      />
    );
  }

  if (biogenicEmissionsMode === PCFBiogenicEmissionsMode.Exclude && component.pcfExcluding) {
    return (
      <EmissionValue
        amount={component.pcfExcluding}
        declaredUnit={DeclaredUnit.kilogram}
        suffixType='kgCO2e'
        abbreviateUnit={true}
        amountTextClassname='font-bold'
        unitTextClassname='font-light'
      />
    );
  }

  return null;
};

export default ComponentPcfValue;
