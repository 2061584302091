import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import GlobeIcon from '../../../../../../assets/icons/Globe';
import LocationTreeSelect from '../../../../../../components/dialog-manager/dialogs/add-product-dialog/components/location-tree-select/LocationTreeSelect';
import { useGetProductById } from '../../../../../../hooks/api/products';
import { Locale } from '../../../../../../i18n';
import EmissionValue from '../../../../../../shared/components/emission-value/EmissionValue';
import {
  CountryCodeToLabelMap,
  RegionCodeToLabelMap,
} from '../../../../../../shared/helpers/country-region-helpers';
import { parseDateWithoutTime } from '../../../../../../shared/helpers/parse-date';
import { textifyDeclaredUnit } from '../../../../../../shared/helpers/textify-numerical-footprint-values-with-their-units';
import ProductInplaceEdit from '../../../product-inplace-edit/ProductInplaceEdit';
import styles from './ProductDetailsTab.module.css';

const ProductDetailsTab = (): JSX.Element => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { productId } = useParams() as { productId: string };

  const { data: product, isLoading } = useGetProductById(productId);

  const [newLocationValue, setNewLocationValue] = useState<string | undefined>(
    product?.country ?? product?.region,
  );

  const getLocationText = (): string => {
    if (product?.country) {
      return CountryCodeToLabelMap[product?.country] || product?.country;
    }

    if (product?.region) {
      return RegionCodeToLabelMap[product?.region] || product?.region;
    }

    return 'UNKNOWN';
  };

  const getLocationIcon = (): JSX.Element | null => {
    if (product?.country && CountryCodeToLabelMap[product.country]) {
      return (
        <span
          className={classNames(
            `fi fi-${product.country.toLowerCase()} border-round align-items-center justify-content-center`,
            styles['flag-icon'],
          )}
        />
      );
    }

    if (product?.region) {
      return <GlobeIcon />;
    }

    return null;
  };

  return (
    <div
      className={classNames(
        'flex flex-column gap-6 mt-2 md:mt-4 w-12 md:w-8',
        styles['product-details-tab'],
      )}
    >
      {/* General Details */}
      <div className='flex flex-column gap-4'>
        <h4>
          {t('productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.title')}
        </h4>

        <div className='grid grid-nogutter gap-4 md:gap-0'>
          {/* Product Id */}
          <div className='col-12 md:col-4 md:pr-6 flex flex-column'>
            <span className='font-medium'>
              {t(
                'productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.productId',
              )}
            </span>
            {isLoading ? (
              <Skeleton height='1rem' className='my-1 py-3 w-full' />
            ) : (
              <>
                <ProductInplaceEdit
                  fieldId='product_id'
                  value={product?.productId}
                  textClassname='font-light text-black-alpha-60'
                  displayClassname='font-light'
                  undefinedText={t('global.notSpecified')}
                />
              </>
            )}
          </div>

          {/* Location */}
          <div className='col-12 md:col-8 flex flex-column'>
            <span className='font-medium'>
              {t(
                'productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.location',
              )}
            </span>

            {isLoading ? (
              <Skeleton height='1rem' className='my-1 py-3 w-full' />
            ) : (
              <ProductInplaceEdit
                fieldId='location'
                type='other'
                value={newLocationValue}
                textClassname='font-light text-black-alpha-60'
                displayClassname='font-light'
                undefinedText={t('global.notSpecified')}
                customDisplay={
                  <div className='flex flex-row gap-2 line-height-1 align-items-center font-light'>
                    {getLocationIcon()}
                    {getLocationText()}
                  </div>
                }
                customContent={
                  <LocationTreeSelect
                    onValueSelect={(regionCode, countryCode) => {
                      setNewLocationValue(countryCode || regionCode);
                    }}
                    value={newLocationValue}
                    placeholder='Select location'
                    wrapperClassname='w-full'
                  />
                }
              />
            )}
            <span />
          </div>
        </div>

        {/* Product Description */}
        <div className='flex flex-column'>
          <span className='font-medium'>
            {t(
              'productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.productDescription',
            )}
          </span>

          {isLoading ? (
            <Skeleton height='4rem' className='my-1 py-3 w-full' />
          ) : (
            <ProductInplaceEdit
              fieldId='description'
              value={product?.description}
              textClassname='font-light text-black-alpha-60'
              displayClassname='font-light'
              undefinedText={t('global.notSpecified')}
              isTextArea
              optional
            />
          )}
        </div>
      </div>

      <Divider className='p-0 m-0 visible md:hidden' />

      {/* Active PCF */}
      <div className='flex flex-column gap-2'>
        <h4>{t('productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.title')}</h4>

        {product?.pcf ? (
          <div className='grid grid-nogutter gap-4 md:gap-0'>
            {/* Pcf Id */}
            <div
              className={classNames('col-12 md:pr-4 flex flex-column gap-1', {
                'md:col-4': product?.pcf?.activeCarbonFootprintId,
                'md:col-2': !product?.pcf?.activeCarbonFootprintId,
              })}
            >
              <span className='font-medium'>
                {t('productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.pcfId')}
              </span>
              {isLoading ? (
                <Skeleton height='1rem' className='py-3 w-full' />
              ) : product?.pcf?.activeCarbonFootprintId ? (
                <span className='font-light text-black-alpha-60'>
                  {product?.pcf?.activeCarbonFootprintId}
                </span>
              ) : (
                t('global.notSpecified')
              )}
            </div>

            {/* Date Created */}
            <div className='col-12 md:col-2 md:pr-4 flex flex-column gap-1'>
              <span className='font-medium'>
                {t(
                  'productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.dateCreated',
                )}
              </span>

              {isLoading ? (
                <Skeleton height='1rem' className='py-3 w-full' />
              ) : product?.pcf?.activeCarbonFootprintCreatedDate ? (
                <span className='font-light text-black-alpha-60'>
                  {parseDateWithoutTime(
                    product?.pcf?.activeCarbonFootprintCreatedDate ?? '',
                    language as Locale,
                  ).toLocaleString()}
                </span>
              ) : (
                t('global.notSpecified')
              )}
            </div>

            {/* Declared Unit */}
            <div className='col-12 md:col-2 md:pr-4 flex flex-column gap-1'>
              <span className='font-medium'>
                {t(
                  'productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.declaredUnit',
                )}
              </span>
              {isLoading ? (
                <Skeleton height='1rem' className='py-3 w-full' />
              ) : (
                <span className='font-light flex flex-row gap-1 text-black-alpha-60'>
                  {product?.pcf?.activeCarbonFootprintDeclaredUnit &&
                  product.pcf?.activeCarbonFootprintUnitaryProductAmount ? (
                    <div className='flex flex-row w-full align-items-center gap-1'>
                      <span>
                        {/* Amount */}
                        {product.pcf?.activeCarbonFootprintUnitaryProductAmount}
                      </span>
                      <span>
                        {/* Unit */}
                        {textifyDeclaredUnit(product?.pcf?.activeCarbonFootprintDeclaredUnit)}
                      </span>
                    </div>
                  ) : (
                    t('global.notSpecified')
                  )}
                </span>
              )}
            </div>

            {/* Total */}
            <div className='col-12 md:col-4 flex flex-column gap-1'>
              <span className='font-medium'>
                {t('productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.total')}
              </span>
              {isLoading ? (
                <Skeleton height='1rem' className='py-3 w-full' />
              ) : (
                <div className='font-light text-black-alpha-60'>
                  {product?.pcf?.pcfExcludingBiogenic ? (
                    <EmissionValue
                      amount={product?.pcf?.pcfExcludingBiogenic}
                      declaredUnit={product?.pcf?.activeCarbonFootprintDeclaredUnit}
                      suffixType='kgCO2e'
                    />
                  ) : (
                    t('global.notSpecified')
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <span className='font-light text-black-alpha-60'>
            {t(
              'productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.noActivePcfText',
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsTab;
