/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Divider } from 'primereact/divider';
import { Navigate, useParams } from 'react-router-dom';

import { useGetFootprintById } from '../../hooks/api/footprints';
import AdditionalDetailsSection from './components/additional-details-section/AdditionalDetailsSection';
import EmissionsSection from './components/emissions-section/EmissionsSection';
import GoBackToPcfListButton from './components/go-back-to-pcf-list-button/GoBackToPcfListButton';
import PcfGeneralDetailsSection from './components/pcf-general-details-section/PcfGeneralDetailsSection';

const FootprintDetailsPage = (): JSX.Element => {
  const { productId, footprintId } = useParams() as { productId: string; footprintId: string };

  // Getting the footprint details
  const { isError } = useGetFootprintById(footprintId, {
    enabled: !!footprintId,
    retry: 0,
  });

  // If footprint details not found then navigate to 404 page
  if (isError) {
    return <Navigate to='/404' />;
  }

  return (
    <div>
      <GoBackToPcfListButton productId={productId} />

      <div className='grid grid-nogutter mt-4'>
        <div className='flex flex-column md:pr-8 col-12 md:col-8 md:gap-6'>
          <PcfGeneralDetailsSection />

          <Divider className='p-0 my-4 visible md:hidden' />

          <EmissionsSection />
        </div>

        <Divider className='p-0 my-4 visible md:hidden' />

        <div className='flex flex-row bg-red col-12 md:col-4'>
          <AdditionalDetailsSection />
        </div>
      </div>
    </div>
  );
};

export default FootprintDetailsPage;
