import { DeclaredUnit } from './IProductDetails';

/**
 * This is a enum that represents the foot print status that are supported
 */
export enum FootPrintStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DEPRECATED = 'deprecated',
}

export enum PartialFullPcf {
  CRADLE_TO_GATE = 'Cradle-to-gate',
  CRADLE_TO_GRAVE = 'Cradle-to-grave',
}

export enum PFStatus {
  ACTIVE = 'Active',
  DEPRECATED = 'Deprecated',
}

export type PrecedingPfId = {
  id: string;
};

/**
 * Strictly positive decimals (e.g., > 0).
 */
export type StrictlyPositiveDecimal = number;

/**
 * Positive decimal weight (e.g., > 0).
 */
export type PositiveDecimalWeight = number;

/**
 * Emissions percentage (e.g., 0 to 100).
 */
export type ExemptedEmissionsPercent = number;

/**
 * Generic text type for descriptive fields.
 */
export type Text = string;

/**
 * Geography Country Subdivision (e.g., state, province).
 */
export type GeographyCountrySubdivision = string;

/**
 * Geography country type.
 */
export type GeographyCountry = string;

/**
 * Characteristic for a region or subregion.
 */
export type GeographyRegionOrSubregionCharacteristic = string;

/**
 * Set of cross-sectoral standards used in the carbon footprint calculation.
 */
export type CrossSectoralStandardSet = string[];

/**
 * Characterization factors used in carbon footprint calculations.
 */
export enum CharacterizationFactorsCharacteristic {
  AR5 = 'AR5',
  AR6 = 'AR6',
}

/**
 * Allocation rule for waste incineration emissions.
 */
export enum AllocationWasteIncinerationCharacteristic {
  CUT_OFF = 'cut-off',
  REVERSE_CUT_OFF = 'reverse cut-off',
  SYSTEM_EXPANSION = 'system expansion',
}

export const AllocationWasteIncinerationCharacteristicToLabelMap = {
  [AllocationWasteIncinerationCharacteristic.CUT_OFF]: 'Cut-off',
  [AllocationWasteIncinerationCharacteristic.REVERSE_CUT_OFF]: 'Reverse cut-off',
  [AllocationWasteIncinerationCharacteristic.SYSTEM_EXPANSION]: 'System expansion',
};

/**
 * Percentage value (0-100).
 */
export type Percent = number;

/**
 * Set of sources for emission factors used in carbon footprint calculations.
 */
export type EmissionFactorDSSet = {
  secondary_emission_factor_source: Text;
};

/**
 * Constraint for defining a decimal between 1 and 3 including.
 */
export type DqiNumberTrait = number;

/**
 * Indicators representing the data quality (e.g., low, medium, high).
 */
export type DataQualityIndicators = {
  coverage_percent: Percent;
  technological_DQR: DqiNumberTrait;
  temporal_DQR: DqiNumberTrait;
  geographical_DQR: DqiNumberTrait;
  completeness_DQR: DqiNumberTrait;
  reliability_DQR: DqiNumberTrait;
};

/**
 * Boolean characteristic indicating whether packaging emissions are included.
 */
export type BooleanCharacteristic = boolean;

/**
 * Only positive emission values (>0) are valid
 */
export type PositiveEmissions = number;

/**
 * Characteristic for defining (positive or negative) emissions in context of a PCF (Product Carbon Footprint)
 */
export type PositiveOrNegativeEmission = number;

/**
 * Only negative emission values (<=0) are valid.
 */
export type NegativeEmissions = number;

export type GeographyRegionOrSubregion = string;

export enum CrossSectoralStandardsUsed {
  ISO_STANDARD_14067 = 'ISO Standard 14067',
  ISO_STANDARD_14044 = 'ISO Standard 14044',
  PATHFINDER_V1 = 'Pathfinder v1',
  PATHFINDER_V2 = 'Pathfinder v2',
  PAS_2050 = 'PAS 2050',
  ISO_STANDARD_14040 = 'ISO Standard 14040',
  ISO_STANDARD_14041 = 'ISO Standard 14041',
  ISO_STANDARD_14042 = 'ISO Standard 14042',
  ISO_STANDARD_14043 = 'ISO Standard 14043',
  PEF = 'PEF',
  OTHER = 'Other',
  GHG_PROTOCOL_PRODUCT_STANDARD = 'GHG Protocol Product Standard',
}

export enum CrossSectoralStandardsUsedV2 {
  ISO_STANDARD_14067 = 'ISO Standard 14067',
  ISO_STANDARD_14044 = 'ISO Standard 14044',
  PATHFINDER_V2 = 'Pathfinder v2',
  ISO_STANDARD_14040 = 'ISO Standard 14040',
  PEF = 'PEF',
  OTHER = 'Other',
}

export enum ProductOrSectorSpecificRuleOperator {
  PEF = 'PEF',
  EPD_INTERNATIONAL = 'EPD International',
  OTHER = 'Other',
}

export interface ProductOrSectorSpecificRule {
  operator: string;
  other_operator_name?: string;
  product_or_sector_specific_rules: {
    rule_name: string;
  }[];
}

export type CrossSectoralStandard = {
  cross_sectoral_standard: CrossSectoralStandardsUsed;
};

export type CrossSectoralStandardV2 = {
  cross_sectoral_standard: CrossSectoralStandardsUsedV2;
};

export type PCFEntity = {
  declared_unit: DeclaredUnit;
  unitary_product_amount: StrictlyPositiveDecimal;
  product_mass_per_declared_unit: PositiveDecimalWeight;
  exempted_emissions_percent: ExemptedEmissionsPercent;
  exempted_emissions_description?: Text;
  boundary_processes_description?: Text;
  geography_country_subdivision?: GeographyCountrySubdivision;
  geography_country?: GeographyCountry;
  geography_region_or_subregion: GeographyRegionOrSubregion;
  reference_period_start: string;
  reference_period_end: string;
  cross_sectoral_standards_used: CrossSectoralStandardV2[];
  product_or_sector_specific_rules: ProductOrSectorSpecificRule[];
  characterization_factors: CharacterizationFactorsCharacteristic;
  allocation_rules_description?: Text;
  allocation_waste_incineration: AllocationWasteIncinerationCharacteristic;
  primary_data_share?: Percent;
  secondary_emission_factor_sources: EmissionFactorDSSet[];
  data_quality_rating?: DataQualityIndicators;
  packaging_emissions_included: BooleanCharacteristic;
  pcf_excluding_biogenic: PositiveEmissions;
  pcf_including_biogenic?: PositiveEmissions | null;
  fossil_ghg_emissions?: PositiveEmissions | null;
  biogenic_carbon_emissions_other_than_co2?: PositiveEmissions;
  biogenic_carbon_withdrawal?: PositiveEmissions;
  dluc_ghg_emissions?: PositiveEmissions;
  lu_ghg_emissions?: PositiveOrNegativeEmission;
  aircraft_ghg_emissions?: PositiveEmissions;
  packaging_ghg_emissions?: PositiveEmissions;
  distribution_stage_pcf_excluding_biogenic?: PositiveEmissions;
  distribution_stage_pcf_including_biogenic?: PositiveOrNegativeEmission;
  distribution_stage_fossil_ghg_emissions?: PositiveEmissions;
  distribution_stage_biogenic_carbon_emissions_other_than_co2?: PositiveEmissions;
  distribution_stage_biogenic_carbon_withdrawal?: NegativeEmissions;
  distribution_stage_dluc_ghg_emissions?: PositiveEmissions;
  distribution_stage_lu_ghg_emissions?: PositiveOrNegativeEmission;
  carbon_content_total?: PositiveEmissions | null;
  fossil_carbon_content?: PositiveEmissions | null;
  carbon_content_biogenic?: PositiveEmissions | null;
  distribution_stage_aircraft_ghg_emissions?: PositiveEmissions;
};

export type IFootPrintTableRow = {
  id: string;
  version: number;
  status: FootPrintStatus;
  dateCreated: string;
  pcfExcludingBiogenic?: number;
  declaredUnit?: DeclaredUnit;
};

export type IFootPrintDetails = {
  id: string;
  product_id: string;
  status: FootPrintStatus;
  spec_version: string;
  partial_full_pcf: PartialFullPcf;
  preceding_pf_ids?: PrecedingPfId[];
  version: number;
  date_created: string;
  pf_status: PFStatus;
  validity_period_start?: string;
  validity_period_end?: string;
  comment?: string;
  company_name: string;
  company_ids: string[];
  product_description?: Text;
  product_ids: string[];
  product_code_cpc: string;
  product_name: string;
  pcf: Partial<PCFEntity>;
  pcf_legal_statement?: string;
  date_published?: string;
};

export const packagingEmissionValueToLabelKey = (value: string): string => {
  switch (value) {
    case 'false':
      return 'global.excluded';
    case 'true':
      return 'global.included';
    default:
      return 'global.notSpecified';
  }
};
