import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetFootprintById } from '../../../../hooks/api/footprints';
import useDialogContext from '../../../../hooks/dialog/useDialogContext';
import { getIncompleteSections } from '../../../../shared/helpers/footprint-helpers';

interface PublishPcfButtonProps {
  productId: number;
  pcfId: string;
}

const PublishPcfButton = ({ pcfId, productId }: PublishPcfButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const { openDialog } = useDialogContext();

  // Getting the footprint details
  const { data: footprintDetails } = useGetFootprintById(pcfId, {
    enabled: !!pcfId,
    retry: 0,
  });

  // Prepare tooltip text based on incomplete sections
  const tooltipText = useMemo(() => {
    if (footprintDetails) {
      const incompleteSections = getIncompleteSections(footprintDetails);

      if (incompleteSections.length > 0) {
        const incompleteSectionsTranslated = incompleteSections.map((section) => {
          return t(section);
        });

        const fullTextTranslationKey =
          incompleteSectionsTranslated.length > 1
            ? 'productDetailsPage.tabs.pcfTab.publishButtonIncompleteTooltipTextMultiple'
            : 'productDetailsPage.tabs.pcfTab.publishButtonIncompleteTooltipTextSingle';

        return t(fullTextTranslationKey, {
          sectionName: incompleteSectionsTranslated.join(` ${t('global.and')} `),
        });
      }
    }
  }, [footprintDetails]);

  const onClick = () => {
    openDialog('publish-pcf-confirmation-dialog', {
      productId,
      pcfId,
    });
  };

  return (
    <div>
      <Tooltip target='.publish-pcf-button-wrapper' />

      {/* Need to wrap the button for the tooltip because of a bug in PrimeReact */}
      {/* When you explicitly use tooltip="blabla..." in button props, it will create the tooltip element under the root, not button */}
      {/* Thus, when the button is unmounted faster than the tooltip element, the browser crashes */}
      <div
        className='publish-pcf-button-wrapper'
        data-pr-tooltip={tooltipText}
        data-pr-position='left'
      >
        <Button
          onClick={onClick}
          label={t('productDetailsPage.buttons.publish')}
          icon='pi pi-arrow-right'
          iconPos='right'
          className='w-full md:w-auto md:min-w-max'
          disabled={!!tooltipText}
        />
      </div>
    </div>
  );
};

export default PublishPcfButton;
