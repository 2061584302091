import { IFootPrintDetails } from '../interfaces/IFootprint';

// Function to check if mandatory fields are present during publish PCF
// And it will return an array of strings, which are translation keys of the sections that have missing mandatory fields
export const getIncompleteSections = (footprintDetails?: IFootPrintDetails): string[] => {
  const sections: string[] = [];

  // Check PCF General Details Section
  if (
    footprintDetails?.product_code_cpc == null ||
    footprintDetails?.pcf?.declared_unit == null ||
    footprintDetails?.pcf?.product_mass_per_declared_unit == null
  ) {
    sections.push('footprintDetailsPage.sections.pcfGeneralDetailsSection.title');
  }

  // Check Emissions Section
  if (footprintDetails?.pcf?.pcf_excluding_biogenic == null) {
    sections.push('footprintDetailsPage.sections.emissionsSection.title');
  }

  // Check Additional Details Section
  if (
    footprintDetails?.pcf?.cross_sectoral_standards_used == null ||
    footprintDetails?.pcf?.cross_sectoral_standards_used.length === 0 ||
    footprintDetails?.pcf?.product_or_sector_specific_rules == null ||
    footprintDetails?.pcf?.product_or_sector_specific_rules.length === 0 ||
    footprintDetails?.pcf?.secondary_emission_factor_sources == null ||
    footprintDetails?.pcf?.secondary_emission_factor_sources.length === 0 ||
    footprintDetails?.pcf?.allocation_waste_incineration == null ||
    footprintDetails?.pcf?.exempted_emissions_percent == null ||
    footprintDetails?.pcf?.packaging_emissions_included == null ||
    footprintDetails?.partial_full_pcf == null ||
    footprintDetails?.pcf?.reference_period_start == null ||
    footprintDetails?.pcf?.reference_period_end == null
  ) {
    sections.push('footprintDetailsPage.sections.additionalDetailsSection.title');
  }

  return sections;
};
