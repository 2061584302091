export enum CognitoErrorCode {
  UserNotFoundException = 'userNotFound',
  NotAuthorizedException = 'notAuthorized',
}

export enum CognitoErrorMessage {
  UserDisabledException = 'User is disabled.',
  UserNotFoundException = 'Username/client id combination not found.',
}

// Enums used for MFA login error cases
export enum CognitoMfaErrorName {
  NotAuthorizedException = 'NotAuthorizedException',
  SignInException = 'SignInException',
  CodeMismatchException = 'CodeMismatchException',
}

export enum CognitoMfaErrorMessage {
  TooManyInvalidAttempts = 'Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.',
  InvalidCode = 'Invalid code or auth state for the user.',
  SessionExpired = 'Invalid session for the user, session is expired.',
  AttemptedWithoutSigningIn = 'An error occurred during the sign in process.This most likely occurred due to:1. signIn was not called before confirmSignIn.2. signIn threw an exception.3. page was refreshed during the sign in flow and session has expired.',
}
